<template>
	<div class="container" @click="click_free">
		<ScrollNotice />
		<div class="main">
			<div class="head">
				<div class="head_search">
					<el-input placeholder="请输入内容" v-model="inputValue" class="input-with-select" size="large"
						prefix-icon="el-icon-search">
						 
						
						<el-select v-model="inputSelect" disabled  slot="prepend" placeholder="医生">
							 <el-option label="全部" value="0"></el-option>
							<el-option label="医生" value="1"></el-option>
							<el-option label="西医" value="2"></el-option>
							<el-option label="中医" value="3"></el-option>
							<el-option label="中西结合" value="4"></el-option>
						</el-select>
						
						<el-button slot="append" @click="search_name" class="input-btn" size="mini">搜索</el-button>
					</el-input>
					<el-divider direction="vertical"></el-divider>
				</div>
				<div class="head_card">
					<div class="head_ad"></div>
					<div class="head_count">
						<p>在线医生 <span>{{ys}}</span> 人</p>
						<p>在线用户 <span>{{yh}}</span> 人</p>
					</div>
				</div>
			</div>
			<div class="title">
				搜询医生 <small>以下为综合自动排序，排名不分先后</small>
			</div>
			<div class="option">
				<div class="option_tip">
					<span class="label">医生</span>
					<el-divider direction="vertical"></el-divider>
					<p>有<span>{{search_ys}}</span>人符合条件</p>
				</div>
				<div class="option_tags">
					<span class="label">您已选择</span>
					<el-divider direction="vertical"></el-divider>
					<div>
						<el-tag v-for="item in selectTags" 
							:key="item" effect="plain" size="mini"
							 closable
							@close="handleClose(tag,item.name,item.id)" 
							style="margin-right: 10px;;" class=" ">
							{{ item.name }}
						</el-tag>
					</div>
				</div>
				<div class="option_select" :class="{expend: isExpend}">
					<el-button class="label" type="text" @click="isExpend = !isExpend">{{isExpend?'收起':'更多选择'}}
					</el-button>
					<el-divider direction="vertical"></el-divider>
					<div class="option_items">




						<div>

							<div style="float: left;  ">

								<el-radio-group v-if="show_close_doing_type" v-model="options.type"
									@change="dct_type_change">
									<el-radio-button label="2">西医</el-radio-button>
									<el-radio-button label="1">中医</el-radio-button>
									<el-radio-button label="3">中西结合</el-radio-button>
								</el-radio-group>	

								&nbsp; &nbsp;
								<el-select v-if="show_close_doing_disease" v-model="options.department"
									placeholder="按专业科别搜索" clearable filterable @blur="selectBlur" @clear="selectClear"
									@change="department_type_change">
									<el-option v-for="item in deparment_list" :key="k" :label="item.name"
										:value="item.name"></el-option>
								</el-select>

								<el-select v-if="show_close_doing_disease" v-model="options.disease" placeholder="按疾病搜索"
									clearable filterable @blur="selectBlur" @clear="selectClear"
									@change="disease_type_change">
									<el-option v-for="item in disease_list" :key="item.id" :label="item.value"
										:value="item.name"></el-option>
								</el-select>

							</div>

							&nbsp;


							<!-- 	 //地址  -->
							<div v-if="show_close_doing_region" style="float: left; margin-left: 20px;">
							
							
								<el-button :class="region_show_dow" style="
								background-color: white!important; 
								color: black!important; 
								position: relative;
								border-color: #DCDFE6!important;
								color: #606266!important;
								z-index: 999;
								width: 110px;
								" 
								@click.stop="region_show_function()">国内</el-button>
								<div style="  height: 0px; 	"
								 
							  >
									<el-collapse-transition>
										<div v-show="region_show">
											<div style="border: ;"></div>

											<div style="width: 400px;" 
											@click.stop="age_blur()"
											class="transition-box">

												<el-button @click="all_confirmAddress"
													style="float:left; padding:7px; margin-left:30px" class=" "
													size=" ">全国</el-button>
												<br />
												<br />
												<el-select v-model="options.region" placeholder="选择省" clearable
													filterable filterable @blur="selectBlur" @clear="selectClear"
													@change="region_type_change">
													<el-option v-for="item in address_list" :key="item.id"
														:label="item.name" :value="item.id"></el-option>
												</el-select>
												<el-select clearable filterable v-model="options.currentCity"
													placeholder="选择市" @change="getCurrentDistricts">
													<el-option :label="item.name" :value="item.value"
														v-for="item in currentCities" :key="item.value"></el-option>
												</el-select>
												<el-select clearable filterable v-model="options.currentDistrict"
													placeholder="选择县(区)">
													<el-option :label="item.name" :value="item.value"
														v-for="item in currentDistricts" :key="item.value"></el-option>
												</el-select>
												<br />
												<br />
												<el-button slot="append" @click="confirmAddress" style=" padding:7px;"
													class=" " size=" ">确认地址</el-button>

											</div>
										</div>
									</el-collapse-transition>

								</div>
							</div>
							<!-- 	 //地址 - -->
					<!-- 		<div style="float: left;  border:rebeccapurple 1px  " >|</div> -->
							
							<div v-if="show_close_doing_region" style="float: left;    ">
								<el-select v-model="options.country" placeholder="海外" clearable filterable
									@blur="selectBlur" @clear="selectClear" 
									@change="country_type_change">
									<el-option v-for="item in country_list" 
									:key="item.id" :label="item.name"
										:value="item.country_code"></el-option>
								</el-select>
							</div>



						</div>


						<div v-show="isExpend">




							<div style="float: left;">
								<el-select v-if="show_close_doing_education" v-model="options.education"
									placeholder="学历" clearable filterable @blur="selectBlur" @clear="selectClear"
									@change="education_type_change">

									<el-option v-for="item in educations" :key="item.id" :label="item.name"
										:value="item.value">

									</el-option>

								</el-select>
							</div>
							&nbsp;

							<!-- 	 //年龄  -->
							<div v-if="show_close_doing_age" style="float: left; margin-left: 27px;" @click.stop="click_free">
								<el-button :class="age_show_dow" style="
								background-color: white!important; 
								color: black!important; 
								position: relative; 
								border-color: #DCDFE6!important;
								color: #606266!important; 
								z-index: 999;
								"   
								@click.stop="age_show_function()"
								
								>年龄</el-button>
								<div style="  height: 0px; 	"  	>
									<el-collapse-transition >
										<div v-show="age_show" >
											<div style="border: ;"></div>
											<div class="transition-box"  
											@click.stop="age_blur()"
											  > 
												<br />
												<el-input  
												class="ages" placeholder=" " 
													style="width:70px;"
													v-model="options.age_dow">
												</el-input>
												-
												<el-input  
												placeholder=" " style="width:70px;" v-model="options.age_up">
												</el-input>
												<br />
												<br />
												<el-button slot="append" @click="confirmAge" style=" padding:7px;"
													class=" " size=" ">确认年龄范围</el-button> 
											</div>
										</div>
									</el-collapse-transition>
								</div>
							</div>


							<!-- 	 //年龄 - -->
							&nbsp;
							<el-select v-if="show_close_doing_title" v-model="options.title" placeholder="职称" clearable
								filterable @blur="selectBlur" @clear="selectClear" @change="title_type_change">
								<el-option v-for="item in title_list" :key="key" :label="item.name" :value="item.value">
								</el-option>
							</el-select> 



							&nbsp;
							<el-select v-if="show_close_doing_sex" v-model="options.sex" placeholder="性别" clearable
								@clear="selectClear" @change="sex_type_change">
								<el-option label="男" value="1">
								</el-option>
								<el-option label="女" value="2">
								</el-option>
							</el-select>

							<!-- <el-radio-group v-model="options.sex" @change="sex_type_change">
								<el-radio-button label="1">男</el-radio-button>
								<el-radio-button label="2">女</el-radio-button>
							</el-radio-group> -->

							&nbsp;
							<el-select v-if="show_close_doing_hospital" v-model="options.hospital" placeholder="医院"
								clearable filterable @blur="selectBlur" @clear="selectClear"
								@change="hospital_type_change">
								<el-option v-for="item in hospital_list" :key="item.id" :label="item.name"
									:value="item.id"></el-option>
							</el-select>


						</div>
					</div>
				</div>
			</div>
			<div class="body clearFix">
				<div class="body_left">
					<template v-for="(item,index) in doctors">
						<DocCard :info="item" :key="index" :class="{active: docIndex === index}"
							@click.native="handleDoc(index,item)" />
					</template>
				</div>
				<div class="body_right">
					<h3>排名提前</h3>
					<div class="top5">
						<template v-for="(item,index) in doctors.top">
							<SideCard :info="item" :key="index" :class="{active: topIndex === index}"
								@click.native="handleTop(index)" />
						</template>
					</div>
				</div>
			</div>
			<!-- <div class="page">
      <el-pagination
          background
          layout="prev, pager, next"
          :total="1000">
      </el-pagination>
    </div> -->
		</div>
	</div>
</template>

<script>
	import axios from "axios";
	import doctors from '@/data.js'
	import DocCard from '@/components/DocCard'
	import SideCard from '@/components/SideCard'
	import ScrollNotice from '@/components/ScrollNotice'
	export default {
		name: "SearchIndex",
		components: {
			ScrollNotice,
			DocCard,
			SideCard
		},
		data() {
			return {
				
				show_close_doing_country: true,
				show_close_doing_region: true,
				show_close_doing_disease: true,
				show_close_doing_type: true,
				show_close_doing_age: true,
				show_close_doing_education: true,
				show_close_doing_sex: true,
				show_close_doing_hospital: true,
				show_close_doing_title: true,

				country_list: this.search_nationalities,
				region_show: false,
				age_show_dow: '',
				age_show: false,
				currentDistricts: [],
				currentCities: [],
				title_list: this.title_list,
				address_list: [],
				disease_list: [],
				deparment_list: [],
				ys: 0,
				yh: 0,
				search_ys: 0,
				inputValue: '',
				inputSelect: '1',

				selectTags: [],

				isExpend: true,
				dct_room: [{
					value: '脑科',
					id: '1'
				}, {
					value: '骨科',
					id: '2'
				}],
				//研究生（含博士、硕士）、本科、大专、中专及以下
				educations: this.educations,

				options: {
					age_up: '',
					age_dow: '',
					type: '', // 西医
					department: '', // 专业科室
					disease: '', // 疾病 
					domestic: '1', // 国内
					abroad: '1', // 海外
					education: '', // 学历
					ages: '1', // 年龄
					title: '', // 职称
					sex: '',
					
					region: '', //地区
					currentCity: '', //市
					currentDistrict: '', //区
					
					hospital: '', //医院
					country: '', //国家
				},
				//doctors: doctors,
				topIndex: 0,
				docIndex: null,
				doctors: [],

			}
		},
		watch: {
			'filterData.timer': function(val) {
				console.log(val);
			}

		},


		mounted() {

		},
		created() {
			this.addLable();


			const params = new URLSearchParams();
			params.append('uid', this.id);
			console.log(params);
			axios.post(this.apiUrl + '/api/doctor/index', params)
				.then((response) => {
					console.log(response.data.data);
					this.doctors = response.data.data;
				})
				.catch(function(error) {
					console.log(error);
				}); 


			axios.post(this.apiUrl + '/api/doctor/getSearchInfo')
				.then((response) => {

					this.ys = response.data.data.ys;
					this.yh = response.data.data.yh;
					this.search_ys = response.data.data.ys;
					this.deparment_list = response.data.data.deparment_list;
					this.disease_list = response.data.data.disease_list;
					this.address_list = response.data.data.address_list;
					this.hospital_list = response.data.data.hospital_list;


				})
				.catch(function(error) {
					console.log(error);
				});

		},

		methods: {
			
			click_free(){ 
				 
				
				
				if (this.age_show) {
					this.age_show = false;
					this.age_show_dow = 'age_show_dow_1';
				}   
				
				
				if (this.region_show) {
					this.region_show = false;
					this.region_show_dow = 'age_show_dow_1';
				}
				
				
			}, 
			age_blur() { 
				//this.age_show = false;
			},
			//国家
			country_type_change(str) {
				this.options.country = str;

				this.options.region = '';
				this.options.currentCity = '';
				this.options.currentDistrict = '';

				for (let i in this.country_list) {
					if (this.country_list[i].country_code == this.options.country) {
						this.selectTags.push({
							'name': this.country_list[i].name,
							id: 'region'
						});
					}
				}
				 

				this.show_close_doing_region = false;
				this.selectTagsShow();
			},
			
			//全国地址
			all_confirmAddress(){
				this.options.country = '';
				this.region_show = false;
				this.region_show_dow = 'age_show_dow_1';
				
				this.show_close_doing_region = false;
				
				
				this.selectTags.push({
					'name': '全国',
					id: 'region'
				});
				
				this.selectTagsShow()
				
				
			},
			//地址确认
			confirmAddress() {
				this.options.country = '';
				this.region_show = false;
				this.region_show_dow = 'age_show_dow_1';


				if (this.options.region) {
					var strs = '';
					//省
					for (let i in this.address_list) {
						if (this.address_list[i].id == this.options.region) {
							strs += this.address_list[i].name;
						}
					}

					if (this.options.currentCity) {
						//市
						for (let i in this.currentCities) {
							if (this.currentCities[i].id == this.options.currentCity) {
								strs += this.currentCities[i].name;
							}
						}
					}

					//区 
					if (this.options.currentDistrict) {
						for (let i in this.currentDistricts) {
							if (this.currentDistricts[i].id == this.options.currentDistrict) {
								strs += this.currentDistricts[i].name;
							}
						}
					}


					this.selectTags.push({
						'name': strs,
						id: 'region'
					});


				}


				this.show_close_doing_region = false;

				this.selectTagsShow()
			},

			//地址展示
			region_show_function() {
				
				//情况地址城市
				if (this.region_show) {
					this.region_show = false;
					this.region_show_dow = 'age_show_dow_1';
				} else {
					this.region_show_dow = 'age_show_dow_0';
					this.region_show = true;
				}
			},

			//年龄 款展示
			age_show_function() {
				if (this.age_show) {
					this.age_show = false;
					this.age_show_dow = 'age_show_dow_1';
				} else {
					this.age_show_dow = 'age_show_dow_0';
					this.age_show = true;
				}
			},

			//县区
			getCurrentDistricts() {
				const formData = new FormData()
				//这里是把上传接口所有的参数都append到formData中
				formData.append('id', this.options.currentCity)
				const config = {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}
				axios.post(this.apiUrl + '/api/tool/areas', formData, config).then((res) => {
					this.currentDistricts = res.data.data;
				}) 
			},

			//城市
			getCurrentCities() {
				const formData = new FormData()
				//这里是把上传接口所有的参数都append到formData中
				formData.append('id', this.options.region)
				const config = {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}
				axios.post(this.apiUrl + '/api/tool/citys', formData, config).then((res) => {
					this.currentCities = res.data.data;
				}) 
			},

			selectBlur(e) {
				// 意见类型
				if (e.target.value !== '') {
					this.value = e.target.value;
					this.$forceUpdate() // 强制更新
				}
			},
			selectClear() {
				this.value = ''
				this.$forceUpdate()
			},
			selectChange(val) {
				this.value = val
				this.$forceUpdate()
			},


			//中西医
			dct_type_change(str) {
				this.options.type = str;


				if (this.options.type == 1) {
					this.selectTags.push({
						'name': '中医',
						id: 'type'
					});
				}
				if (this.options.type == 2) {
					this.selectTags.push({
						'name': '西医',
						id: 'type'
					});
				}


				this.show_close_doing_type = false;
				this.selectTagsShow();
			},

			//性别
			sex_type_change(str) {
				this.options.sex = str;

				if (this.options.sex == 1) {
					this.selectTags.push({
						'name': '男',
						id: 'sex'
					});
				}
				if (this.options.sex == 2) {
					this.selectTags.push({
						'name': '女',
						id: 'sex'
					});
				}
				this.show_close_doing_sex = false;
				this.selectTagsShow();
			},

			//科室
			department_type_change(str) {
				this.options.disease = '';
				this.options.department = str;

				this.selectTags.push({
					'name': this.options.department,
					id: 'disease'
				});

				this.show_close_doing_disease = false;
				this.selectTagsShow();

			},

			//疾病 
			disease_type_change(str) {
				//科室清零
				this.options.department = '';
				this.options.disease = str;

				this.selectTags.push({
					'name': this.options.disease,
					id: 'disease'
				});


				this.show_close_doing_disease = false;
				this.selectTagsShow();

			},

			//地区 
			region_type_change(str) {
				this.options.region = str; 
				this.getCurrentCities(); 

			},

			//学历
			education_type_change(str) {
				this.options.education = str;
				for (let i in this.educations) {
					if (this.educations[i].value == this.options.education) {
						this.selectTags.push({
							'name': this.educations[i].name,
							id: 'education'
						});
					}
				}
				this.show_close_doing_education = false;
				
				this.selectTagsShow()
			},

			//职称
			title_type_change(str) {
				this.options.title = str;

				for (let i in this.title_list) {
					if (this.title_list[i].value == this.options.title) {
						this.selectTags.push({
							'name': this.title_list[i].name,
							id: 'title'
						});
					}
				}
				this.show_close_doing_title = false
				this.selectTagsShow()

			},

			//医院
			hospital_type_change(str) {
				this.options.hospital = str;

				for (let i in this.hospital_list) {
					if (this.hospital_list[i].id == this.options.hospital) {
						this.selectTags.push({
							'name': this.hospital_list[i].name,
							id: 'hospital'
						});
					}
				}
				this.show_close_doing_hospital = false
				this.selectTagsShow()

			},


			//年龄范围
			confirmAge(str) {

				if (this.options.age_up && this.options.age_dow) {

					this.age_show = false;
					this.age_show_dow = 'age_show_dow_1';
					this.selectTags.push({
						'name': this.options.age_dow + '-' + this.options.age_up + '岁',
						id: 'age'
					});
					this.show_close_doing_age = false;
					this.selectTagsShow()
				} else {
					alert('请输入完整年龄段;')
				}
			},


			//三方选择工具
			selectTagsShow() { 

				//执行统一搜索 
				const params = new URLSearchParams();
				params.append('options', JSON.stringify(this.options));
				console.log(params);
				axios.post(this.apiUrl + '/api/doctor/searchList', params)
					.then((response) => {
						console.log(response.data.data);
						this.doctors = response.data.data.list;
						this.search_ys = response.data.data.count;
					})
					.catch(function(error) {
						console.log(error);
					});
			},


			search_name() {

				const params = new URLSearchParams();
				params.append('name', this.inputValue);
				console.log(params);
				axios.post(this.apiUrl + '/api/doctor/searchList', params)
					.then((response) => {
						console.log(response.data.data);
						this.doctors = response.data.data.list;
						this.search_ys = response.data.data.count;
					})
					.catch(function(error) {
						console.log(error);
					});

			},

			//添加标签
			addLable() {
				// this.selectTags.push('nihao');
			},
			//关闭标签
			handleClose(tag, val, str) {

				if (val == '中医' || val == '西医') {
					this.options.type = '';
				}
				if (val == '男' || val == '女') {
					this.options.sex = '';
				}
				 
				for (let i = 0; i < this.selectTags.length; i++) {
				    console.log(this.selectTags[i].id);
					 if(this.selectTags[i].id == str){ 
						 this.selectTags.splice(i, 1);
					 }
					 
				}
			
				
				if (str == 'title') {
					this.options.title = '';
					this.show_close_doing_title = true;
				}
				if (str == 'hospital') {
					this.options.hospital = '';
					this.show_close_doing_hospital = true;
				}

				if (str == 'sex') {
					this.options.sex = '';
					this.show_close_doing_sex = true;
				}


				if (str == 'education') {
					this.options.education = '';
					this.show_close_doing_education = true;
				}

				if (str == 'age') {
					this.options.age_dow = '';
					this.options.age_up = '';
					this.show_close_doing_age = true;
				}



				if (str == 'type') {
					this.options.type = '';
					this.show_close_doing_type = true;
				}




				if (str == 'disease') {
					this.options.disease = '';
					this.options.department = '';
					this.show_close_doing_disease = true;
				}



				if (str == 'region') {
					this.show_close_doing_region = true;
				}


				
				this.selectTagsShow()

			},
			doSearch(index) {
				this.activeIndex = index
			},
			handleDoc(index, e) {
				this.docIndex = index
				//alert(index);
				this.$router.push({
					path: `/doctor/${index}?id=` + e.id
				})
			},
			handleTop(index) {
				this.topIndex = index
			}
		}
	}
</script>

<style scoped lang="scss">
	// .ages:hover{
	// 		
	// }

	.age_show_dow_1 {
		border-bottom: 1px solid #1fcddc !important;
	}

	.age_show_dow_0 {
		border-bottom: 0px !important;
	}


	.transition-box {
		//margin-bottom: 10px;
		width: 200px;
		height: 130px;
		//border-radius: 4px;
		background-color: white;
		text-align: center;
		color: black;
		padding: 10px 20px;
		border: 1px solid #DCDFE6;

		//border-top: 0px;
		margin-right: 20px;
		z-index: 888;
		position: absolute;
		margin-top: -1px;
		;
	}

	.main {
		width: 1200px;
		margin: 15px auto;

		.head {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 15px;

			.head_search {
				position: relative;

				.input-with-select {
					width: 780px;

					/*height: 48px;*/
					.input-btn {}
				}

				.el-divider {
					position: absolute;
					left: 114px;
					top: 50%;
					transform: translateY(-50%);
					margin: 0;
					height: 30px;
					background: #f5f5f5;
				}
			}

			.head_card {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;

				.head_ad {
					width: 156px;
					height: 86px;
					background-color: skyblue;
					border-radius: 8px;
					margin-right: 14px;
					background-image: url('./img/ad.jpg');
				}

				.head_count {
					width: 205px;
					height: 86px;
					background-color: #fff;
					border-radius: 8px;
					font-size: 14px;
					display: flex;
					flex-direction: column;
					justify-content: space-evenly;
					align-items: flex-start;
					padding-left: 25px;
					box-sizing: border-box;

					span {
						margin: 0 12px 0 15px;
					}
				}
			}
		}

		.title {
			font-size: 30px;
			font-weight: bold;
			margin: 31px 0;

			small {
				font-size: 10px;
				padding: 0 10px;
				color: #A7A7A7;
				font-weight: 500;
			}
		}

		.option {
			width: 100%;

			/*font-size: 14px;*/
			.option_tip {
				height: 38px;
				width: 1080px;
				background-color: #fff;
				margin-bottom: 10px;
				position: relative;
				padding-left: 120px;

				.label {
					color: #666;
				}

				p {
					line-height: 38px;
					color: #999;
					font-size: 12px;
				}
			}

			.option_tags {
				height: 58px;
				width: 1080px;
				padding-left: 120px;
				background-color: #fff;
				margin-bottom: 10px;
				position: relative;
				line-height: 58px;

				.el-tag--plain {
					color: $green;
					padding: 0 8px 0 14px;
					border-color: $green;
					line-height: 18px;
				}
			}

			.option_select {
				height: 54px;
				/*line-height: 54px;*/
				width: 1080px;
				padding-left: 120px;
				background-color: #fff;
				margin-bottom: 10px;
				position: relative;

				.option_items {
					height: 100%;
					display: flex;
					flex-direction: column;
					justify-content: space-evenly;

					.el-button--mini {
						padding: 4px 15px;
						border-color: #dedede !important;
						color: #3e3e3e !important;
					}

					.el-button-group {
						margin-right: 70px;
						color: #333 !important;
					}
				}

				&.expend {
					height: 156px;
				}
			}

			.label {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 28px;
				font-size: 14px;
				color: #333;
				font-weight: 600;
			}

			.el-divider {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 102px;
				margin: 0;
				height: 26px;
				background-color: #f7f7f7;
			}
		}

		.body {
			margin-top: 18px;

			.body_left {
				width: 80%;
				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: space-between;
				float: left;
			}

			.body_right {
				width: calc(20% - 20px);
				float: right;
				background-color: #fff;
				height: 100%;

				h3 {
					color: $red;
					text-align: center;
					margin: 30px 0;
				}

				.top5 {
					display: flex;
					flex-direction: column;
					align-items: center;
					height: 1660px;
					padding-bottom: 10px;
					justify-content: space-between;
				}
			}
		}

		.page {
			width: 960px;
			display: flex;
			justify-content: center;
			margin: 20px 0 36px;
		}
	}
</style>
<style scoped lang="scss">
	/*搜索框中下拉框*/
	.container /deep/ .el-select .el-input {
		width: 114px;
	}

	.container /deep/ .input-with-select .el-input-group__prepend {
		background-color: #fff;
		border: none;
	}

	.container /deep/ .el-input-group__prepend div.el-select .el-input__inner {
		text-align: center;
		font-size: 14px;
		color: #333;
	}

	/*搜索框输入框*/
	.container /deep/ .el-input-group>.el-input__inner {
		border: none;
		height: 50px;
		font-size: 14px;
	}

	/*搜索框搜索按钮*/
	.container /deep/ .el-input-group__append {
		border: none;
		width: 74px;
		height: 32px;
		text-align: center;
		background-color: #fff;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 1px;
	}

	.container /deep/ .el-input-group__append button.el-button {
		height: 32px;
		width: 74px;
		padding: 0;
		margin: 0;
		font-size: 14px;
	}

	/*搜索框下拉框下拉菜单*/
	.container /deep/ .el-scrollbar__wrap {
		overflow: hidden;
	}

	/*已选择标签*/
	.option_tags /deep/ .el-tag--plain .el-tag__close {
		color: $green;
	}

	.option_tags /deep/ .el-tag--plain .el-tag__close:hover {
		background-color: $green;
		color: #fff;
	}

	.option_items /deep/ .el-input--mini {
		.el-input__inner {
			height: 22px !important;
			line-height: 22px !important;
		}

		.el-input__icon {
			line-height: 22px;
			height: 22px;
		}
	}

	/*更多选择下拉框*/
	/deep/ .department.el-select .el-input {
		width: 150px;
	}

	/deep/ .disease.el-select .el-input {
		width: 120px;
	}

	/deep/ .department.el-select .el-input {
		width: 150px;
	}

	/deep/ .disease.el-select .el-input {
		width: 120px;
	}

	/deep/ .domestic.el-select .el-input {
		width: 108px;
	}

	/deep/ .short.el-select .el-input {
		width: 80px;
	}

	/*更多选择下拉框后的按钮*/
	/deep/ .el-button-group>.el-button {
		float: none !important;
	}
</style>
